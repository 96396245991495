import React from 'react'

function PrivacyPolicyUSA(props) {
  return (
    <section className="privacy-policy">
      <div className="container">
        <div className="privacy-policy__inner">
          <h3 className="privacy-policy__item-heading text--4">
            ADMINISTRATOR'S IDENTITY AND CONTACT DETAILS
          </h3>
          <ul className="privacy-policy__inner-list">
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                The Administrator of your personal data is Emersoft LLC
                [Employer identification number] 921707678, with registered seat at 9620 Las Vegas Blvd S, Ste E4
                #612, Las Vegas, NV 89123 (hereinafter the
                "Administrator").
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                The administrator's contact information is as follows:
              </p>
              <ul className="privacy-policy__inner-sublist">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Address: 9620 Las Vegas Blvd S, Ste E4 #612, Las Vegas, NV 89123
                  </p>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    E-mail:
                    <a
                      href="mailto:hello@emersoft.co"
                      className="footer__contact-link
                                      privacy-policy__link"
                    >
                      hello@emersoft.co
                    </a>
                  </p>
                  <p className="privacy-policy__paragraph text--5">
                    Tel:
                    <a
                      href="tel:+17027572008"
                      className="footer__contact-link
                                      privacy-policy__link"
                    >
                      +1 702 757 2008
                    </a>
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <h3 className="privacy-policy__item-heading text--4 mt-b-20">
            TERMS
          </h3>
          <p className="privacy-policy__paragraph text--5">
            By accessing the web site at <a
            href="https://emersoft.ai"
            className="footer__contact-link text--5"
          >
            https://emersoft.ai
          </a>, you are agreeing to be bound by these web site Terms and Conditions of Use, all
            applicable laws and regulations, and agree that you are responsible for compliance with any applicable local
            laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The
            materials contained in this web site are protected by applicable copyright and trademark law.
          </p>
          <ol className="privacy-policy__list text--4 mt-b-20">
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4">
                USER LICENSE
              </h3>
              <ul className="privacy-policy__inner-list">
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    Permission is granted to temporarily download one copy of the materials (information or software) on
                    Emersoft LLC’s web site for personal, non-commercial transitory viewing only. This is the grant of a
                    license, not a transfer of title, and under this license you may not:
                  </p>
                  <ul className="privacy-policy__inner-sublist">
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        modify or copy the materials;
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        use the materials for any commercial purpose, or for any public display (commercial or
                        non-commercial);
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        attempt to decompile or reverse engineer any software contained on Emersoft LLC’s web site;
                      </p>
                    </li>
                    <li className="privacy-policy__item">
                      <p className="privacy-policy__paragraph text--5">
                        remove any copyright or other proprietary notations from the materials; or v. transfer the
                        materials to another person or “mirror” the materials on any other server.
                      </p>
                    </li>
                  </ul>
                </li>
                <li className="privacy-policy__item">
                  <p className="privacy-policy__paragraph text--5">
                    This license shall automatically terminate if you violate any of these restrictions and may be
                    terminated by Emersoft LLC at any time. Upon terminating your viewing of these materials or upon the
                    termination of this license, you must destroy any downloaded materials in your possession whether in
                    electronic or printed format.
                  </p>
                </li>
              </ul>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">
                DISCLAIMER
              </h3>
              <p className="privacy-policy__paragraph text--5">The materials on Emersoft LLC’s web site are provided “as
                is”. Emersoft LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other
                warranties, including without limitation, implied warranties or conditions of merchantability, fitness
                for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                Further, Emersoft LLC does not warrant or make any representations concerning the accuracy, likely
                results, or reliability of the use of the materials on its Internet web site or otherwise relating to
                such materials or on any sites linked to this site.</p>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">
                LIMITATIONS
              </h3>
              <p className="privacy-policy__paragraph text--5">
                In no event shall Emersoft LLC or its suppliers be liable for any damages (including, without
                limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use
                or inability to use the materials on Emersoft LLC’s Internet site, even if Emersoft LLC or an Emersoft
                LLC authorized representative has been notified orally or in writing of the possibility of such damage.
                Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability
                for consequential or incidental damages, these limitations may not apply to you.
              </p>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">
                REVISIONS AND ERRATA
              </h3>
              <p className="privacy-policy__paragraph text--5">
                The materials appearing on Emersoft LLC’s web site could include technical, typographical, or
                photographic errors. Emersoft LLC does not warrant that any of the materials on its web site are
                accurate, complete, or current. Emersoft LLC may make changes to the materials contained on its website
                at any time without notice. Emersoft LLC does not, however, make any commitment to update the materials.
              </p>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">
                LINKS
              </h3>
              <p className="privacy-policy__paragraph text--5">
                Emersoft LLC has not reviewed all of the sites linked to its Internet web site and is not responsible
                for the contents of any such linked site. The inclusion of any link does not imply endorsement by
                Emersoft LLC of the site. Use of any such linked web site is at the user’s own risk.
              </p>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">
                SITE TERMS OF USE MODIFICATIONS
              </h3>
              <p className="privacy-policy__paragraph text--5">
                Emersoft LLC may revise these terms of use for its web site at any time without notice. By using this
                web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
              </p>
            </li>
            <li className="privacy-policy__item">
              <h3 className="privacy-policy__item-heading text--4 mt-b-20">GOVERNING LAW</h3>
              <p className="privacy-policy__paragraph text--5">
                Any claim relating to Emersoft LLC’s web site shall be governed by the laws of Nevada without regard to
                its conflict of law provisions. General Terms and Conditions applicable to Use of a Web Site.
              </p>
            </li>
          </ol>
          <h3 className="privacy-policy__item-heading text--4 mt-b-20">
            PRIVACY POLICY
          </h3>
          <p className="privacy-policy__paragraph text--5">
            Your privacy is important to us, and it is Emersoft LLC’s policy to respect your privacy regarding any
            information we may collect while operating our websites. Accordingly, we have developed this Policy in order
            for you to understand how we collect, use, communicate and disclose and make use of personal information.
            The following outlines our privacy policy.
          </p>
          <ol className="privacy-policy__list text--4 mt-b-20">
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                Before or at the time of collecting personal information, we will identify the purposes for which
                information is being collected.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                We will collect and use of personal information solely with the objective of fulfilling those purposes
                specified by us and for other compatible purposes, unless we obtain the consent of the individual
                concerned or as required by law.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                We will only retain personal information as long as necessary for the fulfillment of those purposes.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                We will collect personal information by lawful and fair means and, where appropriate, with the knowledge
                or consent of the individual concerned.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                Personal data should be relevant to the purposes for which it is to be used, and, to the extent
                necessary for those purposes, should be accurate, complete, and up-to-date.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                We will protect personal information by reasonable security safeguards against loss or theft, as well as
                unauthorized access, disclosure, copying, use or modification.
              </p>
            </li>
            <li className="privacy-policy__item">
              <p className="privacy-policy__paragraph text--5">
                We will make readily available to customers information about our policies and practices relating to the
                management of personal information.
              </p>
            </li>
          </ol>
          <p className="privacy-policy__paragraph text--5">
            We are committed to conducting our business in accordance with these principles in order to ensure that the
            confidentiality of personal information is protected and maintained. Emersoft LLC may change its Privacy
            Policy from time to time, and at Emersoft LLC’s sole discretion.
          </p>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicyUSA
